<template>
  <div class="container-fluid" id="main1">
    <!-- v-if="me && me.id>0"> -->
    <div class="row main-content">
      <div class="col sidebar">
        <div class="text-left " style="margin-top:90px;">


          <div class="search-form px-3 my-4 py-3" >
            <div class="form-group">
              <label
                class="form-label font-weight-bold"
                for="searchInput"
              >{{$t('sidebar.vyhledavani')}}:</label>
              <input
                id="searchInput"
                type="search"
                class="form-control form-control-sm"
                :placeholder="$t('sidebar.vyhledavani-placeholder')"
                v-model="q"
                v-on:keyup="hledani()"
              />
            </div>
          </div>




          <div class="title">
            <p class="mb-1">
              <i class="fas fa-check text-s mr-1"></i>
              {{$t('sidebar.uzivatel')}}:
            </p>
          </div>

          <div class="user mx-3">
            <p class="mb-0">{{$t('sidebar.name')}}: {{me.name ? me.name : me.email}}</p>

            <p v-if="(me.company ? me.company.name : null) || me.company_name  " class="font-weight-bold mb-1">
              {{$t('sidebar.company')}}: {{ (me.company ? me.company.name : null) || me.company_name  }}
            </p>

            <p class="mb-0">{{$t('sidebar.country')}}: {{me.country}}</p>
          </div>



          <div class="mx-3 mt-4">
            <p class="font-weight-bold mb-3">{{$t('sidebar.kde-jsem')}}:</p>
            <ul class="pl-3">
              <li>
                <router-link to="/main">{{$t('page.hlavni-stranka')}}</router-link>
                <ul class="pl-2">
                  <li>
                    <router-link to="/main/motors">{{$t('page.seznam-motoru')}}</router-link>
                  </li>
                  <ul class="pl-2">
                    <li v-if="$route.params.id">
                       <router-link :to="'/main/motors/' + $route.params.id">{{$t('page.karta-motoru')}}</router-link>
                    </li>
                  </ul>
               <!--   <li>
                    <a href="#">Další stránka</a>
                  </li>-->
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col content">
        <div class="row h-100 pb-2 mb-2">
          <div class="col px-3 px-sm-4">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
<!--
    <div class="row bottom-content justify-content-center p-2">
      <div class="mobile-app col col-10 text-center">
        <h4 class="h6 font-weight-bold mb-2">{{$t('mobile-app.stahnete-si')}}</h4>
        <div class="store-icons text-center">
          <a href="https://play.google.com/store" target="_blank">
            <img class="google" src="/img/google-play-badge_CS.png" alt />
          </a>
          <a href="https://www.apple.com/cz/ios/app-store/" target="_blank">
            <img class="apple" src="/img/App_Store_Badge_CS.svg" alt />
          </a>
        </div>
      </div>
    </div>
    -->
  </div>
</template>
<script>
// @ is an alias to /src

//const qs = require("qs");
//const axios = require("axios");
//axios.defaults.withCredentials = true;

export default {
  name: "main2",
  components: {},
  data() {
    return {
      q:''
    };
  },
  mounted() {
    console.log("mounted main 1");
    this.$parent.$refs.noticebox.refreshData()



  },
  computed: {
    me() {
      if (this.$store.getters.me) {

        return this.$store.getters.me;
      }
      else
        return {}
    }
  },
  eatch:{

  },
  methods: {


    hledani(){


      for(let i in this.$children){
        var el = this.$children[i]
        if (typeof el.sortovat == 'function'){
          el.sortovat({q: this.q, order: this.orderBy})
        }
      }

    }


  }
};
</script>

<style>
img {
  max-width: 80%;
}
</style>
